import React, { useEffect, useState } from "react";

const TableOfContent = () => {
	const [headings, setHeadings] = useState([]);
	const [showList, setShowList] = useState(true);

	useEffect(() => {

		const elements = Array.from(document.querySelectorAll(".blog-detail-topic-body h2, .blog-detail-topic-body h3, .blog-detail-topic-body h4")).map((elem) => {
			elem.setAttribute("id", `toc-title-${Math.random()}`);
			return {
				text: elem.innerText,
				id: elem.getAttribute("id"),
				className: elem.tagName === "H3" ? "sub-item" : elem.tagName === "H4" ? "sub-sub-item" : ""
			};
		});
		setHeadings(elements);
	}, []);
	const setShowContent = () => {
		setShowList(!showList);
	};
	const scrollToHeading = (id) => {
		const element = document.getElementById(id);
		const offset = 130;
		const bodyRect = document.body.getBoundingClientRect().top;
		const elementRect = element.getBoundingClientRect().top;
		const elementPosition = elementRect - bodyRect;
		const offsetPosition = elementPosition - offset;

		window.scrollTo({
			top: offsetPosition,
			behavior: "smooth",
		});
	};
	return (
		headings.length > 0 && (
			<div className="blog-table-content p-3 bg-light mb-3">
				<h5 className="mb-2">
					Table of Content
					<small className="ml-2">
						[
						<a href="javascript: void(0)" onClick={() => setShowContent()}>
							Hide
						</a>
						]
					</small>
				</h5>
				{showList && (
  <ul>
    {headings
      .filter((heading) => heading.text) // Filter out null or empty text
      .map((heading) => (
        <li key={heading.text} className={heading.className}>
          <a
            href="javascript:void(0)"
            onClick={() => scrollToHeading(heading.id)}
          >
            {heading.text}
          </a>
        </li>
      ))}
  </ul>
)}
			</div>
		)
	);
};
export default TableOfContent;
